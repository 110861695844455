import { Controller } from 'stimulus'
import Swiper, { Navigation, EffectFade } from 'swiper'

Swiper.use([Navigation, EffectFade])

export default class extends Controller {
  static targets = ['tray', 'menuContent']

  connect() {
    this.initMenu()
    window.addEventListener('load', this.updateMenuContentHeight.bind(this))
  }

  initMenu() {
    // Create menuTray Swiper instance
    const menuTraySelector = '.js-menuTray'
    const menuTrayElement = this.element.querySelector(menuTraySelector)
    const menuTraySlidesToShow = parseInt(menuTrayElement.dataset.slidestoshow) || 'auto'

    if (menuTrayElement) {
      this.menuTray = new Swiper(this.trayTarget, {
        slidesPerView: 2,
        slidesPerGroup: 1,
        spaceBetween: 10,
        freeMode: false,
        slidesOffsetAfter: 0,
        centeredSlides: true,
        initialSlide: 1,
        speed: 800,
        threshold: 35,
        resistance: true,
        resistanceRatio: 0.85,
        breakpoints: {
          700: {
            slidesPerView: menuTraySlidesToShow,
            freeMode: true,
            spaceBetween: 10,
            centeredSlides: false,
            centeredSlidesBounds: true,
            threshold: 70,
            initialSlide: 0,
          },
          1280: {
            slidesPerView: menuTraySlidesToShow,
            freeMode: true,
            spaceBetween: 10,
            centeredSlides: false,
            allowTouchMove: false,
            threshold: 128,
            initialSlide: 0,
          }
        }
      })

      // Set the active slide based on the screen width
      this.menuTray.activeIndex = window?.innerWidth < 700 ? 1 : 0;
      this.menuTray.updateSlidesClasses();
    }

    // Create menuContent Swiper instance
    const menuContentSelector = '.js-menuContent'
    const menuContentElement = this.element.querySelector(menuContentSelector)

    if (menuContentElement) {
      this.menuContent = new Swiper(this.menuContentTarget, {
        slidesPerView: 1,
        effect: 'fade',
        fadeEffect: {
          crossFade: true
        },
        autoHeight: true,
        navigation: false,
        freeMode: false,
        centeredSlides: false,
        initialSlide: 1,
        threshold: 35,
        resistance: true,
        resistanceRatio: 0.85,
        spaceBetween: 0,
        speed: 600,
        allowTouchMove: false,
        breakpoints: {
          700: {
            threshold: 70,
            centeredSlides: true,
            initialSlide: 0,
          },
          1280: {
            initialSlide: 0,
          }
        }
      })

      // Set the active slide based on the screen width
      this.menuContent.activeIndex = window?.innerWidth < 700 ? 1 : 0;
      this.menuContent.updateSlidesClasses();

    }

    // Make sure both instances exist before linking
    if (this.menuTray && this.menuContent) {

      let menuContent = this.menuContent;

      // When the active slide of menuTray changes, update the active slide of menuContent
      this.menuTray.on('slideChange', function() {
        menuContent.slideTo(this.activeIndex);
        this.updateSlidesClasses();
      })
    }
  }

  slideTo(e) {
    let index = e.currentTarget.dataset.index
    this.menuContent.slideTo(index);
    this.menuTray.activeIndex = index;
    if (window?.innerWidth < 700) {
      this.menuTray.slideTo(index);
    }
    this.menuTray.updateSlidesClasses()
  }

  /**
   * Workaround for autoHeight not working on some edge cases
   * - initial slide has significantly less content than at least one other slide
   * - the height of the largest slide is at least 150% of the viewport height
   * - the page loads with the menu in view, and the above conditions are met
   */
  updateMenuContentHeight() {
    this.menuContent?.updateAutoHeight()
  }
}
